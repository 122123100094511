import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import Section from "../components/section"
import Wave from "../components/wave"
import staticdata from "../../staticdata.json"
import Cell from "../components/cell"
import styled from "styled-components"
import Tracker from "../components/tracker"

import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"

const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94a4ba;
  text-align: center;
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: { min: 2017, max: 2019 },
      list: ["Planned", "In Progress", "Completed"],
    }
  }

  plannedOnly = () => {
    this.setState({
      list: [""],
    })
  }

  inProgressOnly = () => {
    this.setState({
      list: ["In Progress"],
    })
  }

  completedOnly = () => {
    this.setState({
      list: ["Completed"],
    })
  }

  all = () => {
    this.setState({
      list: ["Planned", "In Progress", "Completed"],
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <div className="Hero">
          <div className="HeroGroup">
            <h1>
              Learn to <br /> design and code React apps
            </h1>
            <p>
              Complete courses about the best tools and design systems.
              Prototype and build apps with React and Swift
            </p>
            <Link to="/page-2/">
              <button>go to portfolio</button>
            </Link>

            {/* <div className="Logos">
          <img src={require("../images/gatsby-icon.png")} width="50" />
          <img src={require("../images/gatsby-icon.png")} width="50" />
          <img src={require("../images/gatsby-icon.png")} width="50" />
          <img src={require("../images/gatsby-icon.png")} width="50" />
          <img src={require("../images/gatsby-icon.png")} width="50" />
          <img src={require("../images/gatsby-icon.png")} width="50" />
        </div> */}

            <Wave />
          </div>
          <img className="Planet" src={require("../images/Planet.png")} />
        </div>

        <div className="Cards">
          <h2 className="CardsTitle">Projects</h2>
          <Tracker />
          <div className="Slider">
          <h3>Date</h3>
          <InputRange
            
            minValue={2017}
            maxValue={2020}
            value={this.state.value}
            // formatLabel={value => `Year: ${value}`}
            onChange={value => this.setState({ value })}
          />
          <div>
            <h3>Type</h3>
          </div>
          </div>
          
          <button onClick={this.plannedOnly}>Planned Only</button>
          <button onClick={this.inProgressOnly}>In Progress Only</button>
          <button onClick={this.completedOnly}>Completed Only</button>
          <button onClick={this.all}>All</button>
          <div className="CardGroup">
            {staticdata.cells
              .filter(cell => this.state.list.includes(cell.status))
              .map((cell, index) => (
                <Card
                  key={index}
                  title={cell.title}
                  text={cell.text}
                  status={cell.status}
                  image={cell.image}
                />
              ))}
            <h1>.</h1>
          </div>
        </div>
        <Section
          image={require("../images/5K_Wallpaper_8.png")}
          logo={require("../images/gatsby-icon.png")}
          title="React for Designers"
          text="Learn how to build a mordern site using React and the most efficient libraries to get your site/product online. Get familiar with the components, grid CSS, animations, interactions, dynamic data with Contentful and deploying your site with Netlify."
        />
        <SectionCaption>12 sections - 6hours</SectionCaption>
        <SectionCellGroup>
          {staticdata.cells.map((cell, index) => (
            <Cell title={cell.title} image={cell.image} key={index} />
          ))}
        </SectionCellGroup>
      </Layout>
    )
  }
}

export default IndexPage
